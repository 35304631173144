import initializeDisaggregationTracking from './disaggregation-tracking.js'
import initializeJumpSeat from './jumpseat-bootstrapper'
import initializeLaunchPolar from './launch-bootstrapper-polar.js'
import initializeLaunch from './launch-bootstrapper.js'

export default function initializeTrackingScripts() {
  const isPolarApp = window.config?.isPolar

  if (isPolarApp) {
    initializeLaunchPolar()
  }
  /*
   * wait until the first app mount event has fired to ensure that
   * we're not initializing trackings scripts during critical initialization time
   * (perf optimization)
   */
  return new Promise((resolve) => {
    const initializeAllTrackingScripts = () => {
      resolve(
        Promise.all([
          initializeDisaggregationTracking(),
          initializeJumpSeat(),
          initializeLaunch(),
        ]),
      )
    }
    window.addEventListener(
      'single-spa:first-mount',
      initializeAllTrackingScripts,
      { once: true },
    )
    window.addEventListener('nwe-rendered', initializeAllTrackingScripts, {
      once: true,
    })
  })
}
