import { loadLoggerAndLogError } from './logger-bootstrapper'

let treatments = {}

export function toggleEnabled(toggle, truthyCheck = 'on', t = treatments) {
  return t[toggle]?.treatment === truthyCheck
}

export default function initializeSplit() {
  return initializeSplitViaServer()
}

export const getPublicToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get('publicToken') ?? ''
}

function initializeSplitViaServer() {
  return Promise.all([System.import('@wf-mfe/toggles')]).then(([toggles]) => {
    const splitInitializePromise = toggles.initialize(
      getPublicToken() ? { mode: 'public' } : window.splitKey,
    )
    // set local value of treatments with this subscription that never ends
    splitInitializePromise.then(() => {
      const allToggles$ = toggles.getAllTreatments$()

      allToggles$.subscribe(
        (t) => {
          treatments = t
        },
        (error) => loadLoggerAndLogError(error),
      )
    })
    return splitInitializePromise
  })
}
